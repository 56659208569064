<template>
  <div v-if="article && article.author">
    <v-container primary fluid>
      <v-container white--text>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="8" xl="8">
            <h1 class="text-h3">{{ article.title }}</h1>
            <h6 class="text-h6">{{ article.author.name }}</h6>
            <span class="text-caption">{{
              as_date(article.published_time)
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="8" xl="8" :class="articleBodyStyle">
          <v-img
            class="mb-8"
            :lazy-src="article.image_url"
            :src="article.image_url"
          ></v-img>
          <vue-markdown>{{ article.body }}</vue-markdown>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "services/api";
import store from "store";
import moment from "moment";
import VueMarkdown from "vue-markdown";

export default {
  components: {
    VueMarkdown
  },
  data: () => ({
    article: {}
  }),
  metaInfo() {
    const currentUrl = window.location.href;
    return {
      title: this.article.title,
      meta: [
        {
          vmid: "description",
          property: "description",
          content: this.article.body
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.article.title
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.article.social_image
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.article.body
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: currentUrl
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: this.article.title
        },
        {
          vmid: "twitter:image",
          property: "twitter:image",
          content: this.article.social_image
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: this.article.body
        },
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: `summary_large_image`
        }
      ]
    };
  },
  created() {
    this.getArticle();
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    articleBodyStyle() {
      if (this.locale.code === "en") {
        return "article-body-en article-body";
      } else {
        return "article-body-ja article-body";
      }
    }
  },
  methods: {
    getArticle() {
      api.getArticle(this.$route.params.slug).then(resp => {
        this.article = resp.data;
      });
    },
    as_date(time) {
      if (this.locale.code === "en") {
        return moment(time).format("MM-DD-YY");
      } else {
        return moment(time).format("YY-MM-DD");
      }
    }
  }
};
</script>

<style scoped>
.article-body-en >>> p {
  font-family: "Times New Roman", Times, serif;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.article-body-ja >>> p {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Segoe UI,
    Hiragino Kaku Gothic ProN, Hiragino Sans, ヒラギノ角ゴ ProN W3, Arial,
    メイリオ, Meiryo, sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 36px;
}

.article-body >>> a {
  text-decoration: underline;
}

.article-body >>> h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.article-body >>> img {
  max-width: 100%;
}

.article-body >>> ul {
  margin-bottom: 12px;
}
</style>
